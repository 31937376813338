<template>
  <div id="portfolio-page">
    <LetsChat></LetsChat>
    <div class="info-generic" :style="{ backgroundImage: 'url(' + hero + ')'}">
      <div class="container center-middle">
        <h2>Partners</h2>
        <p>and clients</p>
      </div>

      <a class="portfolio-arrows-svg" @click="scrollToSmoothly(900, 1500)">
        <svg id="more-arrows">
          <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
          <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
          <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
        </svg>
      </a>
    </div>

    <div class="portfolio-unit-container" id="portfolio-arrow">
      <div class="portfolio-unit"
           v-for="(partners, index ) in allPartners" :key="index">
        <ul class="cards-lists">
          <li>
            <a v-bind:href="partners.website_link" target="_blank" class="card-lists__content">
              <img :src="`${link}${partners.background_image}`" alt="Portfolio with partners">
              <div class="details">
               <span v-html="partners.description" class="title"></span>
                <span class="info">{{ partners.website_link }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="flume-above-footer" :style="{ backgroundImage: 'url(' + heroFlume + ')'}">
      <div id="button-contact">
        <router-link :to="{ name : 'ContactPage'}">
          <button id="contact-us-btn" type="button">
            Contact us
          </button>
        </router-link>
      </div>
    </div>
    <Footer color="#181818">
      <DropDownFooter colorMobile="#181818">
        <DropDown subMenuColor="#181818"></DropDown>
      </DropDownFooter>
    </Footer>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { CMS } from '@/main';

import heroPartners from '@/assets/hero-partners.webp';
import heroFlume from '@/assets/flume-above-footer-sec.png';
import Footer from "../components/Home/Footer";
import DropDownFooter from "../components/FooterMobile/DropDownFooter";
import DropDown from "../components/FooterMobile/DropDown";
import LetsChat from "../components/LetsChat";

export default {
  name: "Portfolio",
  computed: {
    ...mapGetters(["allPartners"]),
  },
  components: {LetsChat, DropDown, Footer, DropDownFooter},

  data() {
    return {
      hero: heroPartners,
      heroFlume: heroFlume,
      link: CMS,
    }
  },
    methods: {
    ...mapActions(['loadPartners']),

    scrollToSmoothly(pos, time) {
      var currentPos = window.pageYOffset;
      var start = null;
      if (time == null) time = 500;
      pos = +pos, time = + time;
      window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
          window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
        } else {
          window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
        }
        if (progress < time) {
          window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, pos);
        }
      });
    }
  },
  created() {
    this.loadPartners();
  }
};
</script>

<style scoped lang="scss">
$anim-speed: 0.3s;

.container {
  clear: both;
  display: block;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}

#portfolio-page {
  height: 100%;
  width: 100%;
}

.info-generic {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: relative;
}

#portfolio-page, .info-generic {
  height: 100vh;
}

.flume-above-footer {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: relative;
  text-align: center;
  height: 50vh;
}

#contact-us-btn {
  display: inline-block;
  border-radius: 99px;
  z-index: 1;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #2d98c8;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 60px;
  letter-spacing: 2px;
}

#contact-us-btn:before,
#contact-us-btn:after {
  content: ' ';
  position: absolute;
  border-radius: 99px;
}

#contact-us-btn:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  background: #131213;
  background: -moz-linear-gradient(left, #131213 0%, #2d98c8 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #131213), color-stop(100%, #2d98c8));
  background: -webkit-linear-gradient(left, #131213 0%, #2d98c8 100%);
  background: -o-linear-gradient(left, #131213 0%, #2d98c8 100%);
  background: -ms-linear-gradient(left, #131213 0%, #2d98c8 100%);
  background: linear-gradient(to left, #131213 20%, #2d98c8 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#2d98c8', GradientType=1);
}

#contact-us-btn:hover {
  color: #fafafa;
  transition: all 0.5s ease;
}

#contact-us-btn:after {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background-color: #131213;
  z-index: -1;
  opacity: 1;
  transition: all 0.6s ease-in-out;
}

#contact-us-btn:hover:after {
  opacity: 0;
}

.center-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.info-generic h2 {
  display: block;
  clear: both;
  text-align: center;
  color: #fff;
  font-size: 100px;
  padding-top: 0 !important;
}

.info-generic h2, .info-generic p, .portfolio-arrows-svg {
  animation: fadein 3.5s;
  -moz-animation: fadein 3.5s;
  -webkit-animation: fadein 3.5s;
  -o-animation: fadein 3.5s;
}

.info-generic p, .portfolio .portfolio-item h3 {
  font-size: 22px;
  display: block;
  text-align: center;
}

.info-generic p {
  clear: both;
  color: #2d98c8;
  font-size: 22px;
  padding-top: 54px;
  float: none;
  line-height: 1.4;
  letter-spacing: .75px;
}

#portfolio-page .info-generic p {
  padding: 0 0 0 185px;
  font-size: 40px;
  font-weight: bold;
}

.portfolio-arrows-svg {
  margin: 0 auto;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15%;
  text-align: center;
}

#more-arrows {
  width: 76px;
  height: 65px;

  &:hover {

    polygon {
      fill: #2d98c8;
      transition: all .2s ease-out;

      &.arrow-bottom {
        transform: translateY(-18px);
      }

      &.arrow-top {
        transform: translateY(18px);
      }
    }
  }
}

polygon {
  fill: #FFF;
  transition: all .2s ease-out;

  &.arrow-middle {
    opacity: 0.75;
  }

  &.arrow-top {
    opacity: 0.5
  }

}

.portfolio-unit {
  position: relative;
  display: inline;

}

.portfolio-unit-container {
  background-color: #1c1c1c;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.portfolio-unit img {
  width: 100%;
  transition: width .3s ease-in-out;
}

ul.cards-lists {
  display: inline;
}

.card-lists__content {
  display: inline-block;
  margin: 0;
  text-align: left;
  opacity: .99;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .05);

  &:before {
    content: '';
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    width: 100%;
    height: 50%;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    transition-property: top, opacity;
    transition-duration: $anim-speed;
  }

  img {
    display: block;
    max-width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  .details {
    font-size: 16px;
    padding: 20px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;

    span {
      display: block;
      opacity: 0;
      position: relative;
      top: 100px;
      transition-property: top, opacity;
      transition-duration: $anim-speed;
      transition-delay: 0s;
    }

    .title {
      line-height: 1;
      font-weight: 600;
      font-size: 22px;
    }

    .info {
      line-height: 1.2;
      margin-top: 5px;
      font-size: 15px;
      opacity: .6;
    }
  }

  &:focus,
  &:hover {

    &:before,
    span {
      opacity: 1;
    }

    &:before {
      top: 50%;
    }

    span {
      top: 0;
    }

    .title {
      transition-delay: 0.15s;
    }

    .info {
      transition-delay: 0.25s;
    }
  }
}

li {
  list-style-type: none;
  display: inline;
}

@media screen and (max-width: 1440px) {
  .flume-above-footer {
    height: 60vh;
  }
}

@media screen and (max-width: 470px) {

  .portfolio-unit-container {
    grid-template-columns: 100%;
    align-items: center;
  }
  .info-generic h2 {
    font-size: 60px;
  }
  #portfolio-page .info-generic p {
    padding: 0 0 0 100px;
    font-size: 25px;
    font-weight: bold;
  }
  #contact-us-btn {
    font-size: 1.3em;
    width: 19em;
    padding: 10px 60px 10px 60px;
  }
  #more-arrows {
    width: 80px;
    height: 70px;
  }
}

@media screen and (max-width: 375px) {
  #contact-us-btn {
    padding: 10px 80px;
    width: 16em;
  }
}

@media screen and (max-width: 330px) {
  #contact-us-btn {
    width: 14em;
  }

}
</style>
